import React from "react";
import PropTypes from "prop-types";

import { useThemeContext } from "../Layout/ThemeContext";
import { removeEmptyKeys } from "../../helpers";

export default function Contacts({ address, hours, telephone }) {
	const { theme } = useThemeContext();

	return (
		<div className={"row"} style={removeEmptyKeys({
			fontFamily: theme.fontFamily,
			fontSize: theme.fontSize.text,
			lineHeight: theme.lineHeight.text,
			color: `${theme.color.text}`,
		})}>
			{ address && <div className={"col-12 text pb-1"}>
				<span className="text_bold">Адрес:</span> {address}
			</div>}
			{ hours && <div className={"col-12 text pb-1"}>
				<span className="text_bold">Время работы:</span> {hours}
			</div>}
			{ telephone && <div className={"col-12 text"}>
				<span className="text_bold">Телефон:</span> {telephone}
			</div>}
		</div>
	);
}

Contacts.propTypes = {
	address: PropTypes.string,
	hours: PropTypes.string,
	telephone: PropTypes.string,
};
