import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Image } from "../Media";
import { useThemeContext } from "../Layout/ThemeContext";

import "./Banner.scss";
import { removeEmptyKeys } from "../../helpers";

// TODO: Need to rename slides to items
export default function HeaderBanner({ slides, children }) {
	const { theme } = useThemeContext();
	const banner = useRef(null);

	return (
		<div ref={banner} className={"banner"}>
			{slides.map(({ title, publicURL, mainImage, mainImageMobile }, idx) => {
				return (
					<div
						key={`banner__item-${idx}`}
						className={"d-flex justify-content-center align-items-center banner__item"}
					>
						<Image
							className={"banner__image"}
							src={publicURL || mainImage}
							alt={title}
							width={"100%"}
							height={"100%"}
							style={{ width: "100%" }}
							responsive={[
								{
									src: mainImageMobile,
									media: "(max-width: 768px)",
								},
							]}
						/>
						<div className={"banner__content container"}>
							<div className={"row justify-content-between"}>
								<div className={"col-12"}>
									<h1
										className={"banner__header"}
										dangerouslySetInnerHTML={{ __html: title }}
										style={removeEmptyKeys({
											fontFamily: theme.fontFamily,
											fontSize: theme.fontSize.h1,
											lineHeight: theme.lineHeight.h1,
											color: theme.color.text,
										})}
									/>
								</div>
							</div>
						</div>
					</div>
				);
			})}
			{children}
		</div>
	);
}

HeaderBanner.propTypes = {
	slides: PropTypes.array,
	children: PropTypes.node,
};

HeaderBanner.defaultProps = {
	slides: [],
	children: <></>,
};
