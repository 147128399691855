import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Anchor } from "antd";
import { isMobile } from "react-device-detect";

import { removeEmptyKeys, translit } from "../../helpers";
import { useThemeContext } from "../Layout/ThemeContext";

import "./CollapseBlock.scss";

const { Link } = Anchor;

export default function Collapse({ config, children, contentBlocks }) {
	const { theme } = useThemeContext();
	const topMenuHeight = 84;
	const [blocks] = useState(contentBlocks);
	const [isCollapsed, setIsCollapsed] = useState(!config.collapse);
	const [isButton, setIsButton] = useState(false);

	const collapse = useRef();
	const slave = useRef();
	const containerRef = useRef();

	// eslint-disable-next-line no-nested-ternary
	const height = isCollapsed
		? config.height + topMenuHeight
		: slave.current
			? slave.current.offsetHeight + topMenuHeight
			: 5000;

	useEffect(() => {
		setIsButton(containerRef?.current?.offsetHeight < slave?.current?.offsetHeight);
	}, [slave.current, containerRef.current]);

	function onCollapse() {
		setIsCollapsed(!isCollapsed);
	}

	return (
		<>
			<div id={"collapse"} ref={collapse} className={"collapse"}>
				{!isCollapsed && (
					<Anchor
						affix={true}
						offsetTop={topMenuHeight}
						className={"collapse-anchor"}
						style={isMobile ? { opacity: 0 } : removeEmptyKeys({
							fontFamily: theme.fontFamily,
							color: theme.color.text,
							maxWidth: "180px",
						})}>
						{blocks.map(
							({ title }, idx) =>
								title && (
									<Link
										href={`#${translit(title)}`}
										title={title}
										key={`${title}${idx}`}
										style={removeEmptyKeys({
											fontSize: theme.fontSize.text,
											color: theme.color.text,
										})}
									/>
								),
						)}
					</Anchor>
				)}
				<div
					className="collapse__wrapper d-noscript-none"
					style={{ height }}
					ref={containerRef}
				>
					<div ref={slave}>{children}</div>
				</div>

			</div>
			{isButton && (
				<button
					className={"link p-3 h3 collapse__button d-noscript-none"}
					onClick={onCollapse}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						color: theme.color.text,
					})}
				>
					{isCollapsed ? "Читать далее" : "Cвернуть"}
				</button>
			)}
		</>
	);
}

Collapse.propTypes = {
	config: PropTypes.object.isRequired,
	children: PropTypes.arrayOf(PropTypes.node),
	contentBlocks: PropTypes.array,
};

Collapse.defaultProps = {
	config: { height: 650 },
	children: [<></>],
	contentBlocks: [],
};
